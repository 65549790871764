import React from "react";
import { Controller } from "react-hook-form";
import {
  Alert,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const QuizGroupSelector = ({
  selections = [],
  control,
  availableQuizGroups,
}) => {
  if (availableQuizGroups?.length > 0) {
    return (
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Alert severity="warning">
          This quiz is part of a company that uses Quiz Groups. That means the
          quiz must belong to one of the available groups below (it may already
          be selected).
        </Alert>
        <Controller
          control={control}
          name="quiz_group_id"
          defaultValue=""
          rules={{ required: "Please select a quiz group" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error} sx={{ mt: 2 }}>
              <InputLabel id="quiz-group-label">Quiz Group</InputLabel>
              <Select labelId="quiz-group-label" label="Quiz Group" {...field}>
                {availableQuizGroups.map((group) => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Stack>
    );
  }

  const companiesWithQuizGroups = selections.filter(
    (company) => company.quiz_groups && company.quiz_groups.length > 0
  );

  if (companiesWithQuizGroups.length) {
    const companyNames = companiesWithQuizGroups.map((company) => company.name);

    return (
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Alert severity="warning">
          These companies require the use of quiz groups:{" "}
          {companyNames.join(", ")}. You must select a quiz group for each
          company.
        </Alert>

        {selections.map((company, index) => {
          if (!company.quiz_groups || company.quiz_groups.length === 0) {
            return null;
          }
          return (
            <Controller
              key={company.id}
              control={control}
              name={`companies.${index}.selectedQuizGroup`}
              defaultValue=""
              rules={{ required: "Please select a quiz group" }}
              render={({ field, fieldState: { error } }) => (
                <FormControl fullWidth error={!!error} sx={{ mt: 2 }}>
                  <InputLabel id={`quiz-group-${company.id}-label`}>
                    {company.name} Quiz Group
                  </InputLabel>
                  <Select
                    labelId={`quiz-group-${company.id}-label`}
                    label={`${company.name} Quiz Group`}
                    {...field}
                  >
                    {company.quiz_groups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          );
        })}
      </Stack>
    );
  }

  return null;
};

export default QuizGroupSelector;
