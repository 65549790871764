import { useEffect, useState } from "react";
import {
  Container,
  Alert,
  Divider,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import AppContainer from "../AppContainer";
import { Loader } from "../Loader";
import MemberList from "./MemberList";
import api from "../../api/endpoints";
import AddUser from "./AddUser";

const PBMDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [accessGranted, setAccessGranted] = useState(false);
  const [members, setMembers] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(
    () =>
      api.pbm
        .accessCheck()
        .then((res) => {
          setAccessGranted(res.data.accessGranted);
        })
        .catch((err) => {
          window.alert(err);
          setLoading(false);
        }),
    []
  );

  const loadData = () => {
    setLoading(true);
    api.pbm
      .index()
      .then(({ data: { members, companies } }) => {
        setMembers(members);
        setCompanies(companies);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (accessGranted) {
      loadData();
    }
  }, [accessGranted]);

  if (loading) {
    return <Loader />;
  }

  if (!accessGranted) {
    return (
      <AppContainer title="PBM Dashboard">
        <Container maxWidth="lg">
          <Alert severity="error">
            You do not have access to this dashboard. Please contact your
            administrator.
          </Alert>
        </Container>
      </AppContainer>
    );
  }

  return (
    <AppContainer title="PBM Dashboard">
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            title="Members"
            action={
              <AddUser companies={companies} onSaveUser={() => loadData()} />
            }
          />
          <Divider />
          <CardContent>
            <MemberList members={members} />
          </CardContent>
        </Card>
      </Container>
    </AppContainer>
  );
};

export default PBMDashboard;
