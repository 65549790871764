import React, { useState } from "react";
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Alert,
  Box,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import Fuse from "fuse.js";

const Status = ({ status }) => {
  if (status === "expired") return <Alert severity="error">Expired</Alert>;
  if (status === "qualified")
    return <Alert severity="success">Qualified</Alert>;
  if (status === "pending") return <Alert severity="warning">Pending</Alert>;

  return <Alert severity="info">{status}</Alert>;
};

const FilteredResults = ({ results, filter }) => {
  if (!results.length) {
    if (filter.length > 0) {
      return <Typography>No members found for "{filter}"</Typography>;
    }

    return <Typography>No members yet. Add one above.</Typography>;
  }

  return (
    <TableContainer sx={{ overflowX: "auto" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Member ID</TableCell>
            <TableCell>Agent</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Qualification Deadline</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((member) => (
            <TableRow key={member.id}>
              <TableCell>{member.full_name}</TableCell>
              <TableCell>{member.pbm_member_id}</TableCell>
              <TableCell>{member.agent}</TableCell>
              <TableCell>{member.email}</TableCell>
              <TableCell>
                {moment(member.qualification_deadline).calendar()}
              </TableCell>
              <TableCell>
                <Status status={member.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MemberList = ({ members }) => {
  const [filter, setFilter] = useState("");
  const [onlyMine, setOnlyMine] = useState(true);

  const options = {
    keys: ["full_name", "pbm_member_id", "email"],
    threshold: 0.2,
  };

  const fuse = new Fuse(members, options);

  let results = filter.length
    ? fuse.search(filter).map((s) => s.item)
    : members;

  if (onlyMine) {
    results = results.filter((m) => m.mine);
  }

  return (
    <>
      <TextField
        label="Member Filter"
        placeholder="E.g., John Smith or jsmith@acme.com or ABC-123"
        variant="filled"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        fullWidth
      />
      {filter.length > 0 && (
        <Button onClick={() => setFilter("")}>Clear Filter</Button>
      )}
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={onlyMine}
              onChange={(e) => setOnlyMine(e.target.checked)}
            />
          }
          label="Show My Members Only"
        />
      </FormGroup>
      <Box sx={{ mb: 3 }} />
      <FilteredResults results={results} filter={filter} />
    </>
  );
};

export default MemberList;
