import React from "react";

const CompanySelect = ({ companies, register }) => {
  if (companies.length === 0) {
    return null;
  }

  if (companies.length === 1) {
    return (
      <input type="hidden" {...register("companyId")} value={companies[0].id} />
    );
  }
};

export default CompanySelect;
