import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Stack, TextField, CardContent } from "@mui/material";
import LoadingButton from "../LoadingButton";
import EmailInput from "../EmailInput";
import DetailDrawer from "../DetailDrawer";
import CompanySelect from "./CompanySelect";
import api from "../../api/endpoints";

const AddUser = ({ onSaveUser, companies }) => {
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      sendWelcomeEmail: true,
    },
  });

  useEffect(() => {
    reset();
  }, [open]);

  const onSubmit = handleSubmit((data) => {
    const formattedData = {
      company_id: data.companyId,
      email: data.email,
      pbm_member_id: data.memberId,
      first_name: data.firstName,
      last_name: data.lastName,
      send_welcome_email: data.sendWelcomeEmail,
    };

    api.pbm
      .createUser(formattedData)
      .then(({ data }) => {
        setOpen(false);
        onSaveUser(data);
      })
      .catch((err) => {
        if (err?.response?.data?.error) {
          window.alert(err.response.data.error);
        } else if (err?.response?.data?.errors) {
          if (err.response.data.errors.length === 1) {
            window.alert(err.response.data.errors[0]);
          } else {
            window.alert(
              "Errors: " + Object.values(err.response.data.errors).join(", ")
            );
          }
        } else {
          window.alert(err);
        }
      });
  });

  return (
    <>
      <Button onClick={() => setOpen(!open)}>+ Add User</Button>
      <DetailDrawer
        title="Add User"
        open={open}
        handleClose={() => setOpen(false)}
        sx={{
          width: {
            xs: "100vw",
            sm: "100vw",
            md: 500,
            lg: 500,
            xl: 500,
          },
        }}
      >
        <CardContent>
          <form onSubmit={onSubmit}>
            <Stack spacing={2}>
              <EmailInput register={register} errors={errors} />
              <TextField
                label={"Member ID"}
                variant="outlined"
                type="memberId"
                {...register("memberId", {
                  required: "You must enter a Member ID",
                })}
                aria-label={"Member ID"}
                fullWidth
                error={!!errors.memberId}
                helperText={errors.memberId && errors.memberId.message}
              />
              <TextField
                label={"First Name"}
                variant="outlined"
                type="firstName"
                {...register("firstName", {
                  required: "You must enter a First Name",
                })}
                aria-label={"First Name"}
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName && errors.firstName.message}
              />
              <TextField
                label={"Last Name"}
                variant="outlined"
                type="lastName"
                {...register("lastName", {
                  required: "You must enter a Last Name",
                })}
                aria-label={"Last Name"}
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName && errors.lastName.message}
              />
              <CompanySelect companies={companies} register={register} />
              {/* <Controller
                control={control}
                name="sendWelcomeEmail"
                render={({ field: { onChange, value, ref } }) => (
                  <FormControlLabel
                    label="Send Invite Email"
                    control={
                      <Checkbox
                        inputRef={ref}
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    }
                  />
                )}
              /> */}
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Save User
              </LoadingButton>
            </Stack>
          </form>
        </CardContent>
      </DetailDrawer>
    </>
  );
};

export default AddUser;
