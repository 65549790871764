import {
  Drawer,
  Card,
  CardHeader,
  IconButton,
  Divider,
  Box,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DetailDrawer = ({
  children,
  title,
  open,
  handleClose,
  width = "100%",
  sx = {},
}) => (
  <Drawer id="drawer" open={open} onClose={handleClose} anchor="right">
    <Card
      id="card"
      sx={{
        height: "100%",
        width: width,
        maxWidth: "100%",
        ...sx,
      }}
    >
      <CardHeader
        title={title}
        action={
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Divider />
      <Box
        sx={(theme) => ({
          height: "calc(100% - 64px)",
          overflowY: "auto",
          backgroundColor: theme.palette.grey[200],
          maxWidth: "100%",
        })}
      >
        {children}
      </Box>
    </Card>
  </Drawer>
);

export default DetailDrawer;
