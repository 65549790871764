import React from "react";
import { TextField, Autocomplete } from "@mui/material";

const CompaniesAutocomplete = ({
  allCompanies,
  errors,
  parentRef,
  ...props
}) => (
  <Autocomplete
    {...props}
    multiple
    openOnFocus
    name="companies_list"
    options={allCompanies}
    getOptionLabel={(option) => option.name}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    filterSelectedOptions
    autoHighlight
    renderInput={(params) => (
      <TextField
        {...params}
        inputRef={parentRef}
        error={"companies" in errors}
        helperText={errors?.companies?.message}
        variant="outlined"
        label="Selected Companies"
        placeholder="Select Companies for Quiz"
      />
    )}
  />
);

export default CompaniesAutocomplete;
